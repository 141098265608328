export default [
  {
    title: 'DownLine',
    icon: 'UsersIcon',
    action: 'B2B',
    resource: 'B2BUser',
    children: [
      {
        title: 'Manage All Users',
        route: 'downline-users',
        action: 'B2B',
        resource: 'B2BUser',
      },
      {
        title: 'Discount Plan',
        route: 'discount-plan',
        action: 'B2B',
        resource: 'B2BUser',
      },
      // {
      //   title: 'Add Discount Plan',
      //   route: 'discount-plan-add',
      //    action: 'B2B',
      //     resource:'B2BUser',
      // },

    ],
  },
]
