<template>
  <b-nav-item @click.stop="getBalance">
    <feather-icon
      v-if="!showBalance"
      size="21"
      icon="BriefcaseIcon"
    />
    <template v-else>
      <span class="cursor-pointer mt-1 mr-2 text-success">{{ balance }} MYR </span>
    </template>
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import UserService from '../../../../../services/user.service'

export default {
  components: {
    BNavItem,
  },
  data() {
    return {
      balance: 0.00,
      showBalance: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },
  methods: {
    getBalance() {
      UserService.getUserBalnce(this.currentUser.loginid).then(
        response => {
          this.showBalance = true
          this.balance = response.balance
        },
        error => {},
      )
    },
  },

}
</script>
